.balloon {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background: #fff;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateY(105%) translateX(-30%);
  width: fit-content;
  height: fit-content;

  @media (max-width: 931px) {
    transform: translateY(105%);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent #fff transparent;
  }
}
