.eventCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  
  .eventCard__image {
    width: 20rem;
    height: 20rem;
    padding: 1px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-0.5rem);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem 0;
    color: #4e4e4e;
  }
}
