.__react_quill_style_for_opinion {
  .quill {
    width: 100%;
    height: 60%;

    .ql-toolbar {
      border-radius: 15px 15px 0 0;
    }

    .ql-container {
      border-radius: 0 0 15px 15px;
    }
  }
}
