.container {
  position: fixed;

  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: auto;

  &.dark {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .modal {
    border-radius: 24px;
    background: #ffffff;
    z-index: 10;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  
    position: relative;

    >div {
      height: 100%;
    }

    &.hiddenOverflow {
      overflow: hidden;
    }

    &.centered {
      position: fixed;
      z-index: 2000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.bottom-left {
      position: fixed;
      z-index: 2000;
      top: auto;
      left: 1rem;
      bottom: 1rem;
    }

    &.nextToItem {
      z-index: 2000;
      top: 2rem;
      left: calc(100% - 13.5rem);
    }

    &.nextToItem.tiny {
      z-index: 2000;
      top: 4.5rem;
    }

    &.big {
      padding: 3rem;
      width: 40rem;
      height: 35rem;
    }

    &.medium {
      padding: 2rem;
      width: 24rem;
      height: 20rem;
    }

    &.small {
      padding: 1rem;
      width: 18rem;
      height: 10.8rem;
    }

    &.tiny {
      padding: 1rem;
      width: 13rem;
      height: 6.5rem;

      border-radius: 5px;

      &::before {
        content: '';
        position: absolute;
        top: -0.7rem;
        left: 50%;
        border-bottom: 0.8rem solid #ffffff;
        border-right: 0.5rem solid transparent;
      }

      &::after {
        content: '';
        position: absolute;
        top: -0.7rem;
        left: calc(50% - 0.5rem);
        border-bottom: 0.8rem solid #ffffff;
        border-right: 0.5rem solid transparent;
        transform: scaleX(-1);
      }
    }

    .close {
      display: none;
      position: absolute;

      top: 0;
      padding: 16px;
      z-index: 11;
      background-color: transparent;
      border: 0;

      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #2c2c2c;
      }

      &.closeRight {
        display: block;
        right: 0;
      }

      &.closeLeft {
        display: block;
        left: 0;
      }
    }
  }
}
