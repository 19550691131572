.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  & > *:not(:first-child) {
    margin-bottom: 5px;
  }

  .title {
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    color: #00C2FF;
  }

  .description {
    font-size: 1.3rem;
    max-width: 800px;
    text-align: center;
    color: #000;

    .link {
      color: #00C2FF;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
