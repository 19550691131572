.container {
  padding-bottom: 1rem;

  .title {
    padding: 0 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 2.5rem 0;

    h1 {
      font-size: 28px;
      font-weight: 800;
      @media (max-width: 930px) {
        font-size: 22px;
      }
      @media (max-width: 630px) {
        font-size: 16px;
      }
    }

    p {
      color: #00c2ff;
      font-size: 22px;
      font-weight: 600;
      @media (max-width: 930px) {
        font-size: 20px;
      }
      @media (max-width: 630px) {
        font-size: 18px;
      }
    }
  }

  .content {
    padding: 0 2rem;
    margin: 2.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 2rem;

      label {
        margin: 0.5rem 0 1.5rem 0;

        color: #000000;
        font-weight: 600;
        font-size: 1.3rem;
      }

      label:last-of-type {
        margin-bottom: 0;
      }

      p {
        display: flex;
        align-items: center;
        color: #cacaca;
        font-size: 1.1rem;
        margin-bottom: 1rem;

        .dot {
          width: 5px;
          height: 5px;
          background-color: #cacaca;
          border-radius: 100%;

          margin: 0 0.5rem;
        }
      }

      .input + label {
        margin-top: 1.5rem;
      }

      .input + .input {
        margin-top: 1rem;
      }

      .input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #d2d2d2;
        color: #d2d2d2;
        width: 100%;
        font-size: 1.2rem;
      }

      .paymentMethodInfo {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        color: #c4c4c4;
        font-size: 1.2rem;

        *:not(svg) {
          margin-right: 0.5rem;
        }

        .creditCard {
          min-width: 55px;
          height: 100%;          
          border-radius: 2px;
          background-color: #222222;
          border: 1px solid #222222;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;

          &.lightBg {
            background-color: #f5f5f5;
          }

          svg path {
            stroke: #fff;
          }
        }

        .cardHiddenNumbers {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .rightContent {
      width: 100%;
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .cancelPolicytitle {
        color: #333333;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .text {
        text-align: end;
        font-size: 1.1rem;
        color: #333333;
        max-width: 600px;

        a {
          font-size: 1.1rem;
          color: #333333;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .bottomContent {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .image {
          margin-left: 1.5rem;
        }
      }

      hr {
        width: 100%;
        border: none;
        border-bottom: 1px solid #7c7c7c;
        margin: 1.5rem 0;
      }
    }
  }

  .actions {
    padding: 0 2rem;
    margin: 10rem 0 2.5rem 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftActions,
    .rightActions {
      display: flex;
      width: 22rem;

      button {
        background-color: transparent;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        color: #000000;

        padding: 0.5rem 0.4rem;
        font-size: 1.2rem;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
        @media (max-width: 930px) {
          font-size: 1rem;
        }
        @media (max-width: 630px) {
          font-size: 0.9rem;
        }
        svg {
          color: #00c2ff;
          margin-right: 0.6rem;
          margin-top: -0.2rem;
        }

        &.cancellationBtn svg {
          color: #fd4f29;
        }

        &:disabled {
          color: #d8d8d8;
          cursor: auto;

          svg {
            color: #d8d8d8;
          }
        }
      }
    }

    .leftActions {
      justify-content: space-between;
    }

    .rightActions {
      justify-content: flex-end;
    }
  }
}

// modal styles
.modalHeader {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 1.2em;
    font-weight: 900;
  }

  strong.blue {
    color: #00c2ff;
    font-weight: 700;
    font-size: 1.1rem;
  }
}

.modalContent {
  margin-top: 1.5rem;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .scrollView {
    overflow-y: scroll;
    height: 80%;
    padding: 0.4rem;
  }

  input {
    border: none;
    border-bottom: 1px solid #d2d2d2;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    width: 100%;
    font-size: 1rem;
  }

  p {
    text-align: justify;
    font-size: 18px;

    span {
      color: #00c2ff;
    }
  }

  p.instructions {
    text-align: justify;
    font-size: 14px;
    font-weight: 600;
    color: #c4c4c4;
  }

  p.centerText {
    text-align: center;
  }

  .rating {
    align-self: flex-start;
    margin-bottom: 1.2rem;
  }

  .opinion {
    width: 100%;
    height: 100%;
  }
}

.modalActions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  box-shadow: 0px 0px 73px 0px #00000026;

  display: flex;
  align-items: center;

  .modalBtn {
    background-color: #00c2ff;
    font-weight: 900;
    width: 14rem;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 22px;
    margin: 1.5rem auto;

    &.modalCancellationBtn {
      background-color: #fd4f29;
    }

    &:disabled {
      background-color: #d8d8d8;
      color: #c4c4c4;
    }
  }
}

.mapContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  h3, h4, p {
    display: flex;
    flex-direction: row-reverse;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    color: #00c2ff;
  }
}
