.navigationBar {
  box-sizing: border-box;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  font-weight: 700;
  color: #00c2ff;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    @media (max-width: 1100px) {
      font-size: 12px;
    }
    @media (max-width: 630px) {
      font-size: 8px;
    }
  }
}

.bodyContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 30rem;
}

.formItem {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.labelComment {
  margin-left: 10px;
}
