.searchSection {
  width: 100%;
}

.searchWithFiltersBar {
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 69px;
  padding: 1.5rem 5px;
}

.searchWithFiltersBar__section {
  position: relative;
  text-align: left;
  margin: 0 1.5rem;
  flex: 1;
  @media (max-width: 900px) {
    margin: 1rem 0;
    text-align: left;
  }

  &:not(:last-child):not(:nth-last-child(2))::after {
    content: "";
    position: absolute;
    top: 0;
    right: -2.2rem;
    height: 100%;
    width: 1px;
    background-color: #d0d0d0;
  }

  .searchWithFiltersBar__section__title {
    color: #000;
    font-size: 1.1rem;
    font-weight: 700;

    @media (max-width: 930px) {
      font-size: 1.3rem;
    }
  }

  .searchWithFiltersBar__section__content {
    font-size: 1rem;

    @media (max-width: 930px) {
      .searchWithFiltersBar__section__content__input {
        width: 100%;
        height: 2.5rem;
        border-bottom: 1px solid #e0e0e0;
        padding: 0 0.2rem;
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }
    }

    .searchWithFiltersBar__section__content__button {
      text-align: start;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      background-color: transparent;
      border: none;
      font-size: 1.1rem;
      margin: 0;
      padding: 0;
      color: #838383;

      cursor: pointer;

      @media (max-width: 930px) {
        height: 2.5rem;
        border-bottom: 1px solid #e0e0e0;
        padding: 0 0.2rem;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }

    .searchWithFiltersBar__section__content__time {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-width: 30rem;

      @media (max-width: 930px) {
        min-width: unset;
        width: fit-content;
      }

      .searchWithFiltersBar__section__content__time__input {
        height: 2.5rem;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        padding: 0 1rem;
        font-size: 1.1rem;
        color: #838383;
      }
    }

    .searchWithFiltersBar__section__content__guests {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 16.5rem;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #d0d0d0;
      }

      .searchWithFiltersBar__section__content__guests__description {
        strong {
          font-size: 1.2rem;
          font-weight: 700;
          color: #000;
        }

        p {
          color: #838383;
          font-size: 1.1rem;
        }
      }

      .searchWithFiltersBar__section__content__guests__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        strong {
          font-size: 1.2rem;
          font-weight: 700;
          color: #626262;
          margin: 0 0.4rem;
        }

        button {
          background-color: transparent;
          border: 1px solid #ccc;
          border-radius: 100%;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.5rem;
          margin: 0;
          padding: 0;
          color: #838383;
          cursor: pointer;
        }
      }
    }
  }
}

.searchWithFiltersBar__button {
  margin-right: 1.5rem;

  @media (max-width: 930px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  button {
    align-self: flex-end;
    background-color: #00c2ff;
    border: none;
    border-radius: 100%;
    color: #fff;
    font-size: 1.2rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 930px) {
      width: unset;
      height: unset;
      padding: 0.5rem 1rem;
      font-size: 1.3rem;
      border-radius: 25px;
      font-weight: 600;
      min-width: 11rem;

      svg {
        margin-top: -0.2rem;
        margin-right: 0.5rem;
      }
    }
  }
}
