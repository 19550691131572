.container {
  width: 290px;
  height: 380px;
  border-radius: 19px;

  box-shadow: 0px 4px 9px 0px #0000001a;

  overflow: hidden;

  .image {
    position: relative;

    width: 100%;
    height: 40%;

    cursor: pointer;

    .favorite {
      position: absolute;

      top: 5px;
      right: 5px;

      color: #fff;

      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 60%;

    .item {
      padding: 15px;

      .title {
        font-size: 20px;
        font-weight: 700;

        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .info {
        display: flex;
        align-items: center;
        width: 100%;

        span:first-of-type {
          position: relative;
          font-size: 14px;

          &::before {
            position: absolute;
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 100%;
            top: calc(50% - 3px);
            right: -8px;
            background-color: #8a8a8a;
          }
        }

        span:last-of-type {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-size: 14px;
          color: #8a8a8a;

          margin-left: 12px;
        }

        svg {
          min-width: 24px;
          min-height: 24px;
        }
      }

      .lastInfo {
        display: flex;
        flex-direction: column;
        margin-left: 4px;

        .infoPiece {
          display: flex;
          align-items: center;

          font-size: 16px;
          color: #8a8a8a;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
