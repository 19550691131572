@media (max-width: 1250px) {
  html .search-results-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  html .search-results-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.container {
  padding-bottom: 5rem;

  .section {
    width: 100%;
    height: 100%;
    display: flex;

    @media (max-width: 930px) {
      flex-direction: column;
    }

    .searchInfo {
      height: 100%;
      width: 20rem;
      min-width: 20%;
      padding: 15px;

      .searchActions {
        display: flex;
        justify-content: space-between;
        text-align: center;
        height: 30;

        strong {
          color: #1c1c1c;
          font-size: 1.1rem;
          font-family: "Catamaran";
          font-weight: 700;
        }

        p {
          color: #b6b6b6;
          font-family: "Catamaran";
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.95rem;
          display: flex;
          align-items: center;
          transition: color 0.2s;

          &:hover {
            color: #7c7c7c;
          }
        }
      }

      .displayFilters {
        width: 100%;
        background-color: #F5F5F5;
        padding: 0.4rem;
        flex-wrap: wrap;
        border-radius: 5px;
        margin-top: 0.4rem;
        display: none;

        &.show {
          display: block;
        }

        .searchItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px;
          background-color: #fff;
          padding: 0.4rem;
          margin: 0.4rem;

          span {
            margin-right: 0.2rem;
            font-family: "Catamaran";
            font-weight: 500;
          }

          span:first-letter {
            text-transform: capitalize;
          }

          svg {
            cursor: pointer;
          }
        }
      }

      .accordions {
        width: 100%;
        margin-top: 2.3rem;

        .typography {
          color: #1E1E1E;
          font-weight: 700;
          font-size: 1.2rem;

          span {
            color: #757575;
            margin: 5px 0;
            font-size: 1.1rem;
            font-weight: 500;

            cursor: pointer;
          }
        }

        .listItem {
          display: flex;
          align-items: center;

          input {
            cursor: pointer;
          }

          label {
            margin-left: 0.6rem;
          }
        }
      }
    }

    .results {
      height: 100%;
      width: 80%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      margin: 10px 20px;

      @media (max-width: 930px) {
        width: 100%;
      }

      .resultsCount {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 930px) {
          width: 100%;
        }

        span {
          color: #676767;
          font-weight: 700;
          font-size: 1.1rem;

          @media (max-width: 930px) {
            font-weight: 500;
            font-size: 0.9rem;
          }
        }

        .filtersBtn {
          background-color: transparent;
          border: none;
      
          display: flex;
          align-items: center;
          justify-content: space-between;
      
          min-width: 8em;
      
          color: #00c2ff;
      
          border: 2px solid #00c2ff;
          border-radius: 13.5px;
          padding: 5px 6px;
      
          .count {
            border-radius: 100%;
            background-color: #00c2ff;
            color: #fff;
            font-size: 12px;
      
            min-width: 18px;
            min-height: 18px;
      
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .listResults {
        margin-top: 2em;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        row-gap: 1.2em;
      }
    }
  }

  .accordionDivider {
    color: #7c7c7c;
    width: 98%;
    margin: 4px auto;
  }
}

#popover {
  .simplePopover {
    position: relative;
    display: grid;
    grid-template-rows: 1.5em 1fr 3.5em;

    width: 100%;
    height: 100%;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 32px;

    background-color: #fff;
    border-radius: 18px;

    overflow-y: scroll;

    > strong {
      font-size: 18px;
      margin-left: 15px;
    }

    .closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;

      cursor: pointer;

      padding: 0;
      border: 0;
      background-color: transparent;
    }

    .list {
      padding: 15px;
    }

    .listFlex {
      display: flex;
      align-items: center;

      input {
        cursor: pointer;
      }

      label {
        margin-left: 0.6rem;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
    }

    .typography {
      color: #757575;
      cursor: pointer;
      margin: 5px 0;
      font-size: 1.1rem;
      font-family: "Catamaran";
    }

    .removeFiltersBtn {
      color: #b6b6b6;
      font-family: "Catamaran";
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      transition: color 0.2s;

      &:hover {
        color: #7c7c7c;
      }
    }

    .popoverActions {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-self: flex-end;
      max-height: 3.5em;

      .seeResultsBtn {
        background-color: #00C2FF;
        font-weight: 900;
        width: 90%;
        color: #fff;
        font-size: 1rem;
        border-radius: 4px;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
      }
    }
  }
}

.searchEventTitle {
  font-size: 3.2rem;
  font-weight: 700;
  margin-left: 8%;
  margin-bottom: 0.5rem;
  text-align: left;
  
  &::first-letter {
    text-transform: capitalize;
  }
}
