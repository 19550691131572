.form {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-family: "Catamaran";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
  }

  .instruction {
    text-align: center;
    font-family: "Catamaran";
    color: #6a6a6a;
  }

  .input {
    width: 100%;
    display: flex;
    justify-content: center;

    .textField {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .newPassBtn {
      width: 267px;
      height: 39px;
      background: #00c2ff;
      border-radius: 19.5px;
      color: #fff;
      margin-bottom: 2rem;

      &:hover {
        background: #00c2ff;
      }

      &:disabled {
        background: #a0a0a0;
      }
    }
  }

  .login {
    margin: 0 auto;

    a {
      color: #00c2ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
