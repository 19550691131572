.navbar {
  width: 100%;
  height: 80px;
  display: flex;
  box-shadow: 0px 4px 73px -14px rgba(0, 0, 0, 0.15);

  &.navbarMobile {
    justify-content: space-between;
  }

  .drawerBtn {
    background-color: transparent;
    border: none;
    color: #00c2ff;
    margin-right: 1em;
  }

  .navbarLogo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo {
    width: fit-content;
    max-height: 6em;
    cursor: pointer;
  }
}

.drawer {
  display: flex;
  flex-direction: column;
}
