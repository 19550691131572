.container {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-family: "Catamaran";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
  }
  .instruction {
    font-family: "Catamaran";
    font-size: 0.95rem;
    color: #4f4f4f;
    text-align: center;
  }
  
  .input {
    width: 100%;
    display: flex;
    justify-content: center;

    .textField {
      width: 60%;
      margin-bottom: 20px;
    }

    >div {
      width: 60%;
    }
  }

  select {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    border: 1px solid #fff;
    border-bottom: 1px solid #969595;
    font-size: 1.1rem;
    color: #767676;
  }

  .policyTerms {
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      width: 50%;
    }

    a {
      color: #00c2ff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .registerBtn {
      background-color: #00c2ff;
      width: 267px;
      height: 39px;
      border-radius: 19.5px;
      color: #fff;
      font-weight: bold;

      &.disabled {
        background-color: #c3c3c3;
      }
    }
  }

  .moreActions {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 1.5rem;

    span {
      color: #00c2ff;
      cursor: pointer;
    }
  }
}
