.header {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;

  strong {
    font-size: 1.3rem;
    font-weight: 900;
  }
}

.content {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;

    display: flex;
    align-items: center;

    transition: all 0.2s ease-in-out;

    &:hover {
      color: #00c2ff;
      font-weight: 500;
    }

    svg {
      margin-right: 0.5rem;
      margin-top: -0.3rem;
    }
  }
}
