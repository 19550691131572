@media (max-width: 930px) {
  html .companyCard {
    width: 40%;

    .cardFooter {
      padding: 0.4rem;

      .footerItem {
        .itemLabel {
          font-size: 0.8rem;
        }

        .itemValue {
          font-size: 0.85rem;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  html .companyCard {
    width: 65%;

    .cardRating {
      svg {
        width: 16px;
      }

      span {
        font-size: 12px;
      }
    }

    h3 {
      font-size: 16px;
    }

    .cardText {
      font-size: 13px;
    }

    .cardFooter {
      padding: 0.3rem;

      .footerItem {
        .itemLabel {
          font-size: 0.8rem;
        }

        .itemValue {
          font-size: 0.85rem;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  html .companyCard {
    .cardFooter {
      .footerItem {
        .itemLabel {
          font-size: 0.9rem;
        }

        .itemValue {
          font-size: 0.95rem;
        }
      }
    }
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  min-height: 5rem;

  .footerItem {
    display: flex;
    flex-direction: column;

    .itemLabel {
      margin-left: 10px;
      font-size: 0.85rem;
      color: #8e8e8e;
      font-family: "Catamaran", sans-serif;
      font-weight: bold;
    }

    .itemValue {
      margin-left: 10px;
      font-size: 0.95rem;
      color: #585858;
      font-family: "Catamaran", sans-serif;
      font-weight: bold;
    }
  }
}

.companyCard {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 40px;

  .companyCardImg {
    width: 100%;
    height: 100%;
    min-height: 20rem;
    border-radius: 40px 40px 0 0;
    text-align: center;
    color: #707070;
    font-size: 1.1rem;
    font-weight: 600;
    overflow: hidden;

    img {
      min-height: 320px;
      width: 100%;
      height: 100%;
    }

  }
  .cardContainer {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  .cardRating {
    overflow: hidden;
  }

  h3 {
    font-family: "Catamaran", sans-serif;
    font-size: 20px;
    font-weight: bold;
  }

  .cardText {
    font-family: "Catamaran", sans-serif;
    font-size: 14px;
    color: #585858;
    font-weight: bold;
  }
}
