@media (max-width: 930px) {
  html .sideImg {
    display: none;
  }

  html .container {
    justify-content: center;
  }

  html .logo img {
    max-height: 8rem;
  }

  html .registerContainer {
    width: 90%;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;

  .sideImg {
    width: 40%;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .registerContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
  
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      img {
        max-height: 10rem;
        cursor: pointer;
      }
    }
  }
  
}
