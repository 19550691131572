.searchBackground {
  height: 25rem;
  width: 100vw;
  position: relative;

  .searchBackground__image {
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    filter: brightness(0.6);
  }

  .searchBackground__content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    z-index: 1;
  }
}
