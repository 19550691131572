@media (max-width: 930px) {
  html .section .info {
    flex-direction: column;
  }

  html .images {
    width: 100%;
  }

  html .reservationDetails {
    align-items: center;
    margin-top: 40px;

    > div {
      width: 100% !important;
    }
  }

  html .comments {
    grid-template-columns: repeat(1, 1fr);
  }

  html .comment {
    text-align: center;

    > div {
      flex-direction: column;
      align-items: center;
    }

    p {
      text-align: center !important;
    }
  }
}

.loadingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.section:first-of-type {
  padding-top: 40px;
}

.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 40px;

  .sectionHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 3rem;
      color: #282828;

      @media (max-width: 930px) {
        font-size: 2rem;
      }
    }

    .headerContent {
      display: flex;

      span {
        display: flex;
        align-items: center;
        margin: 0 20px;
        cursor: pointer;
      }

      p {
        font-size: 1.3rem;
        margin-left: 0.6rem;
      }
    }
  }

  .sectionContent {
    .score {
      display: flex;
      align-items: center;

      .halfStar {
        fill: url(#half-star-color);
      }

      .fullStar {
        fill: yellow;
      }

      .emptyStar {
        fill: #ccc;
      }
    }

    .buffetAddressInfo {
      font-family: Catamaran;
      font-weight: bold;
      font-size: 16;
      color: #8A8A8A;
      margin: 0px 5px;
    }
  }

  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .images {
      width: 50%;
      height: fit-content;

      >div:first-of-type {
        box-shadow: 0px 25px 72px -11px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        overflow: hidden;
      }

      .sliderImg {
        width: 100%;
        height: 460px;
      }

      .descriptionContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 2.2rem;

        h2 {
          color: #282828;
          font-size: 2.3rem;
        }

        div {
          text-align: justify;
          color: #404040;
          font-size: 1.4rem;
        }
      }
    }

    .reservationDetails {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .content {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0px 25px 72px -11px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        padding: 15px 35px;

        h2 {
          margin-bottom: 1.2rem;
          font-size: 1.5rem;
        }

        .reservation {
          width: 100%;
          height: 12.5rem;
          border: 1px solid #939393;
          border-radius: 10px;
          display: flex;
          flex-direction: column;

          .firstRow {
            width: 100%;
            display: flex;

            .date {
              border-bottom: 1px solid #939393;
              cursor: pointer;
              position: relative;

              p:first-of-type {
                font-weight: 700;
                font-size: 1rem;
                width: 100%;
              }

              p:last-of-type {
                font-size: 1.1rem;
                color: #7C7C7C;
                width: 100%;
                padding: 5px;
              }
            }

            .period {
              border-bottom: 1px solid #939393;
              flex: 1;
              border-left: 1px solid #939393;

              p {
                font-weight: 700;
                font-size: 1rem;
                width: 100%;
              }

              .select {
                width: 100%;
                color: #7C7C7C;
                font-size: 1.1rem;
              }
            }

            .date, .period {
              height: 6.25rem;
              padding: 0.95rem;
              width: 50%;
            }
          }

          .invited {
            flex: 1;
            height: 6.25rem;
            padding: 0.95rem;

            p {
              font-weight: 700;
              font-size: 1rem;
              width: 100%;
            }

            .select {
              width: 100%;
              color: #7C7C7C;
              font-size: 1.1rem;
            }
          }
        }

        .reservationBtn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 30px 0 10px 0;

          .btn {
            background-color: #00C2FF;
            color: #FFFFFF;
            height: 51px;
            width: 264px;
            font-weight: 900;
            font-size: 1.2rem;
            border-radius: 15px;
            cursor: pointer;
          }

          .btnDisabled {
            background-color: #A0A0A0;
            cursor: auto;
          }
        }

        >p {
          width: 100%;
          text-align: center;
          color: #6C6C6C;
          font-size: 0.95rem;
        }

        >p.shown {
          margin-top: 0.5rem;
          color: #000000;

          >span {
            color: #00C2FF;
          }
        }

        .priceInfo {
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          h3 {
            font-size: 1.4rem;
          }

          .infoItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 6px 0;

            span {
              color: #7C7C7C;
              font-size: 1.1rem;
            }
          }

          .infoItemTotal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 6px 0;

            span {
              color: #000000;
              font-weight: 700;
              font-size: 1.3rem;
            }
          }
        }
      }

      .reportBtn {
        background-color: transparent;
        border: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5em;
        font-size: 1.2em;
        color: #a0a0a0;
        cursor: pointer;
      }
    }

    .description {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 30px;

      h2 {
        color: #282828;
        font-size: 2.3rem;
      }

      div {
        text-align: justify;
        color: #404040;
        font-size: 1.3rem;
      }
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (max-width: 930px) {
      text-align: center;
    }

    h2 {
      color: #282828;
      font-size: 2.3rem;
    }

    .displayItem:not(.display) {
      border-radius: 40px !important;
    }
    
    .displayItem {
      border: 1px solid #7c7c7c;
      box-shadow: none !important;
    
      &::before {
        background-color: transparent !important;
      }
    
      &.display:first-of-type {
        border-radius: 40px 40px 0 0;
      }
    
      &.display:last-of-type {
        border-radius: 0 0 40px 40px;
      }

      .typography {
        width: 80%;
        flex-shrink: 0;
        font-size: 2.2rem;
        font-weight: 700;
      }

      .details {
        display: flex;

        .value {
          font-size: 1.4rem;
          font-weight: 600;
          color: #454545;
        }

        .listItems {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          padding: 1.2em 4em 1.2em 0;

          .description {
            font-size: 1.4rem;
            font-weight: 600;
            color: #454545;
          }

          .list {
            font-size: 1.4rem;
            font-weight: 600;
            color: #454545;
            display: flex;
            justify-content: space-between;
            width: 60%;

            .items {
              .displayInRow {
                display: flex;
                flex-wrap: wrap;

                .display {
                  margin-left: 0.3rem;
                  margin-right: 0.3rem;
                  margin-top: 0.5rem;

                  strong {
                    margin-left: 0.3rem;
                  }
                }
              }

              .noItems {
                color: #000000;
                font-weight: 600;
                font-size: 1.35rem;
              }

              .title {
                color: #aeaeae;
                font-weight: 700;
                font-size: 1.4rem;
              }

              .display {
                display: flex;
                align-items: center;

                button {
                  background-color: transparent;
                  border-radius: 100%;
                  padding: 0;
                  margin: 0;
                  border: 0px;
                  cursor: pointer;
                  margin-right: 6px;
                  margin-bottom: -4px;

                  &:disabled {
                    cursor: not-allowed;
                  }

                  svg {
                    transition: color 0.2s;
                  }
                }

                strong {
                  margin-right: 6px;
                  color: #000000;
                  font-weight: 600;
                  font-size: 1.35rem;
                }

                p {
                  color: #aeaeae;
                  font-weight: 900;
                  font-size: 1.2rem;
                }
              }
            }
          }

          .packageActions {
            color: #1A1A1A;
            font-size: 2.2rem;
            min-width: 6em;
            font-weight: bold;
            margin-top: -1em;
            display: flex;
            flex-direction: column;
            align-items: center;

            .packageActionsBtn {
              background-color: #00C2FF;
              font-size: 1.2rem;
              font-weight: 900;
              width: 100%;
              height: 40px;
              border-radius: 27px;
              color: #fff;
              margin-top: 0.8em;
            }
          }
        }
      }
    }
    
    .displayItem + .displayItem {
      border-top: none;
    }

    .mapAddress {
      color: #9D9D9D;
      font-size: 1.6rem;
      font-weight: 700;

      @media (max-width: 930px) {
        align-items: center;
      }
    }

    .noComments {
      color: #9D9D9D;
      font-size: 1.6rem;
      font-weight: 700;
    }

    .comments {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;

      .comment {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 2.2em;

        .userImg {
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 100%;
          width: 69px;
          height: 69px;
        
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00c2ff;
        
          box-shadow: 0px 0px 73px 0px #00000026;
        }

        .userInfo {
          display: flex;
          flex-direction: column;
          margin: 0 20px;
          margin-left: 5.95rem;

          span {
            font-weight: 600;
            font-size: 1.4rem;
            margin-top: -12px;
            color: #bebebe;
          }
        }

        .commentContent {
          margin-top: 0.6rem;

          div {
            color: #474747;
            text-align: justify;
            font-size: 1.4rem;
            margin-top: 1.2rem;
            font-weight: 500;
          }
        }
      }
    }

    .showAllCommentsBtn {
      width: 100%;
      display: flex;
      justify-content: center;

      .btn {
        background-color: #00C2FF;
        font-size: 1.2rem;
        font-weight: 900;
        width: 450px;
        height: 5rem;
        border-radius: 27px;
        color: #fff;
        margin-top: 1.5em;
        margin-bottom: 3em;
      }
    }
  }
}


.modalHeader {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 1.2em;
    font-weight: 900;
  }
}

.modalContent {
  width: 100%;
  height: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 4.5em 0;

  p.loginMsg {
    text-align: center;
    font-size: 18px;

    span,
    a {
      color: #00c2ff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.modalActions.login {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8rem;
  width: 100%;
  box-shadow: 0px 0px 73px 0px #00000026;

  overflow: hidden;

  display: flex;
  align-items: center;

  .loginBtn {
    background-color: #00C2FF;
    font-weight: 900;
    width: 14rem;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 22px;
    margin: 1.5rem auto;
  }
}

.showHours {
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
  margin-top: 1.5rem;

  span {
    color: #00C2FF;
  }
}

.deleteButton {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 0.4rem;
  font-size: 1rem;
  color: #000000;

  svg {
    transition: color 0.2s;
    margin-bottom: -3px;
  }

  &:hover {
    svg {
      color: #f33333;
    }
  }
}
