.modal-header-container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 1.2em;
    font-weight: 900;
  }
}

.modal-content-container {
  width: 100%;
  height: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 4.5em 0;

  p {
    font-size: 18px;

    span {
      color: #00c2ff;
      text-decoration: none;
    }
  }
}

.modal-actions-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 73px 0px #00000026;

  overflow: hidden;

  display: flex;
  align-items: center;
}

.separator {
  color: #7C7C7C;
  margin: 50px 0;
  width: 81%;

  @media (max-width: 930px) {
    margin: 50px auto;
    width: 100%;
  }
}

.separator:last-of-type {
  margin-top: 0;
}

.save-card-info {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  input {
    margin-right: 0.5rem;
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;

    margin-top: -0.3rem;
  }
}

.credit-card-item {
  text-transform: capitalize;

  display: flex;
  align-items: center;

  svg {
    margin-top: -0.2rem;

    path {
      stroke: #ffffff;
    }
  }
}

.credit-card-item-body {
  min-width: 40px;
  width: 55px;
  height: 24px;
  overflow: hidden;
  border-radius: 2px;
  background-color: #222222;
  border: 1px solid #222222;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &.lightBg {
    background-color: #f5f5f5;
  }
}

.addressInModalConfirmation {
  display: flex;
  flex-direction: center;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    color: #00c2ff;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5rem;
  }

  > div {
    p {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      color: inherit;
    }

    strong {
      color: #000000;
    }
  }
}
