@media (max-width: 650px) {
  html .choiceCard {
    width: 12rem;

    .cardText {
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  html .choiceCard {
    width: 90%;
  }

  html .choiceCard + .choiceCard {
    margin-top: 1.5rem;
  }
}

.choiceCard {
  transition: all 0.2s ease-in-out;
  height: 260px;
  width: 15rem;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;

  .cardText {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Catamaran';
    margin-top: 20px;
  }
  
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
