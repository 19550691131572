.emailConfirmationForm {
  display: flex;
  flex-direction: column;
  padding-top: 10%;

  .checkImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 20px;
  }

  h1 {
    text-align: center;
    font-family: "Catamaran";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
  }

  .instruction {
    text-align: center;
    font-family: "Catamaran";
    color: #6a6a6a;
  }

  .actions {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 5%;

    p {
      margin-bottom: 20px;

      span {
        color: #00c2ff;
        cursor: pointer;
      }
    }
  }
}
