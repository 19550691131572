.calendar-view-container {
  width: 100%;

  .react-calendar__viewContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .react-calendar__month-view {
      max-width: 13em;
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;

    .react-calendar__navigation__label {
      background-color: transparent;
      border: none;

      display: flex;
      justify-content: space-around;

      span {
        font-size: 18px;
        font-weight: 700;
        color: #000000;
      }

      :nth-child(2) {
        visibility: hidden;
      }
    }

    .react-calendar__navigation__arrow {
      background-color: transparent;
      border: none;
      color: #a3a3a3;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-top: -3px;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    color: #717171;
    font-weight: 700;
    font-size: 13px;
    text-decoration: none;

    &::after {
      content: ".";
    }
  }

  .calendar-tile-common {
    background-color: #ffffff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 32px;
  }

  .calendar-tile-highlight {
    border-radius: 100%;
    background-color: #00c2ff;
    color: #ffffff;
  }
}
