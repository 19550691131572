@media (max-width: 930px) {
  html .container {
    border: none;
    padding-left: 0;
    width: 100%;
    background-color: unset;
    position: relative;
  }
}

.container {
  position: absolute;
  border: 1px solid #7c7c7c;
  min-width: calc(100vw - 55%);
  border-radius: 40px;
  max-height: 500px;
  padding: 22px;
  background-color: #fff;

  .section {
    width: 100%;
    display: flex;
  }

  .picture {
    width: 150px;
    height: 150px;
    background-color: #c4c4c4;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  .score {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .info {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
