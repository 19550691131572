@media (max-width: 930px) {
  html .container {
    .section .title {
      font-size: 30px;
    }

    .sectionFull .title {
      font-size: 30px;
    }
  }
}

@media (max-width: 1200px) {
  html .cards {
    flex-wrap: wrap;
  }
}

.container {
  width: 100%;
  height: 100%;

  .section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    
    .title {
      font-size: 55px;
      text-align: center;
    }
  }

  .fullWidth {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
  }

  .sectionFull {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-of-type .title {
      margin-top: -1.9rem;
    }

    &:last-of-type .title {
      margin-top: 4.4rem;
    }

    .title {
      font-size: 55px;
      text-align: center;
    }

    .eventCards__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 1rem 8rem;
      margin-top: 2rem;
    }

    &.sectionUserExperience {
      position: relative;
      margin-top: 100px;
      background-color: #e5e5e5;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.1;
        display: block;

        background-image: url(../../assets/images/userExpBg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .cards {
        display: flex;
        justify-content: center;
        margin: 2em 4em 10em 4em;
      }
    }
  }
}

.titleAboveBg {
  font-size: 3rem;
  font-weight: 900;
  line-height: 4.2rem;

  @media (max-width: 1270px) {
    font-size: 2.5rem;
    line-height: 3.5rem;    
  }

  @media (max-width: 930px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
  }
}

.instructionAboveBg {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1270px) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  @media (max-width: 930px) {
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
  }
}
