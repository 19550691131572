@media (max-width: 1000px) {
  html .navbar .navbarActions > strong {
    font-size: 17px;
  }

  html .navbarActions .actions {
    font-size: 17px;
    margin-right: 14px;
  }
}

@media (max-width: 930px) {
  html .navbarItems {
    padding-top: 0.6rem;

    margin: 0;

    .items {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
  
      span {
        margin-top: 0.3em;
      }
    }
  }

  html .navbarActions {
    display: flex;
    flex-direction: row;
    justify-content: unset;

    margin: 0 auto;
    margin-top: 0.4rem;

    padding: 1rem;

    strong {
      margin-right: 20px;
    }
  }
}

.loginInfo {
  display: flex;
  margin-right: 10px;

  span {
    align-self: center;
    font-weight: 600;
    font-size: 1.1em;
  }
}

.profilePicture {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;

  background: #c4c4c4;
  cursor: pointer;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.userOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    text-align: left;
    background-color: transparent;
    color: #b9b9b9;
    font-size: 0.95rem;
    border: none;
    cursor: pointer;
  
    transition: color 0.2s ease-in;

    &:hover {
      color: #00c2ff;
    }    
  }
}

.navbarItems {
  display: flex;
  margin-left: 40px;
  flex: 3;

  .items {
    height: 100%;
    display: flex;
    align-items: center;

    .item,
    .itemActive {
      font-size: 1rem;
      margin: 0 10px;
      font-family: "Catamaran", sans-serif;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        border-bottom: 4px solid #00c2ff;
      }
    }
    .itemActive {
      color: #00c2ff;
      border-bottom: 4px solid #00c2ff;
    }
  }
}
.navbarActions {
  flex: 1;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;

  padding-right: 10px;

  .actions {
    font-size: 1rem;
    margin: 0 10px;
    font-family: "Catamaran", sans-serif;
    font-weight: bold;
    cursor: pointer;
    color: #00c2ff;
  }

  > strong {
    font-size: 20px;
    margin-right: 10px;
  }
}
