html .MuiToggleButtonGroup-root {
  border: 1px solid #d3d4d5;
  border-radius: 18px;
  background-color: #f5f6f7;
  padding: 3px;
  transition: background-color 0.3s ease;

  button {
    border: none;
    border-radius: 18px !important;
    background-color: transparent;
    color: #626262;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0.2rem 1rem;
    text-transform: capitalize;
    cursor: pointer;

  }
}
