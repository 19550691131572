.accordion-item {
  background-color: transparent !important;
  box-shadow: none !important;

  &::before {
    display: none !important;
  }

  &.Mui-expanded {
    margin: 0;

    .MuiButtonBase-root .MuiAccordionSummary-content {
      margin: 0;
      min-height: unset;
    }
  }
}

.dropdown-content {
  margin-left: 1em;
}
