@media (max-width: 1250px) {
  .content {
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: center;

    .image {
      padding: 0;
    }

    .card {
      padding: 20px 0 0 0;
      align-items: center;
      justify-content: center;
      width: auto;

      .icons {
        width: 100%;
      }
    }
  }

  .termsContainer {
    padding-top: 20px;

    .copyright,
    .terms a {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 650px) {
  .termsContainer {
    flex-direction: column;
    align-items: center;

    .terms {
      padding: 0;
      flex-direction: column;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 540px;
  border-top-style: solid;
  border-top-width: 10px;
  border-color: #00c2ff;
}

.content {
  display: flex;
  flex: 8;
  padding: 70px 0;

  .image {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 3;
    padding-right: 70px;
  }

  .card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 3;
    padding-right: 70px;
    padding-top: 38px;
    padding-bottom: 38px;

    a {
      font-size: 20px;
      text-decoration: none;
      color: #000000;

      &:hover {
        color: #00c2ff;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .icons {
      display: flex;
      height: 40px;
      justify-content: space-between;

      .twitterIcon,
      .facebookIcon,
      .youtubeIcon,
      .instagramIcon,
      .linkedinIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        height: 40px;
        width: 40px;
        border: 1px solid #00c2ff;
        border-radius: 100%;
        color: #00c2ff;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          background-color: #00c2ff;
          color: #ffffff;
        }
      }
    }
  }
}
.termsContainer {
  display: flex;
  flex: 1;

  .copyright {
    flex: 4;
    padding: 0 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .terms {
    box-sizing: border-box;
    flex: 9;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #ababab;
      text-decoration: none;
      font-size: 18px;
    }
  }
}

.logo {
  max-width: 20rem;
  height: fit-content;
}
