.toast-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  padding: 1rem 0;
  min-width: 18rem;
}

.toast-img-container {
  width: 3.5rem;
  height: 3.5rem;
}

.toast-close-btn {
  position: absolute;
  top: 0;
  right: 0;

  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #b8b8b8;
}

.toast-body h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

.toast-body p {
  margin-top: 0.3rem;
  font-size: 1.05rem;
  font-weight: 500;
  color: #000000;
}

.toast-title-success {
  color: #8BDE38;
}

.toast-title-error {
  color: #FD4F29;
}

.toast-title-warning {
  color: #FFCB00;
}

.toast-title-info {
  color: #00c2ff;
}
