.my-requests-content {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;

  .container {
    margin: 6em 4em;
    width: 100%;
    @media (max-width: 930px) {
      margin: 1em 2em;
    }
    .no-requests {
      font-weight: 600;
      font-size: 1.5em;
    }
  }

  .request-info-container {
    width: 100%;
    border-collapse: collapse;
  }

  .request-info-container thead tr th {
    padding: 0.3em 1em;
    font-weight: 600;
    font-size: 1.2em;
    text-align: left;
    @media (max-width: 1270px) {
      font-size: 0.9em;
    }
    @media (max-width: 650px) {
      font-size: 0.5em;
    }
  }

  .request-info-container tbody tr td {
    border-bottom: 1px solid #e4e4e4;
    color: #b0b0b0;
    font-size: 1.1rem;
    padding: 1.5em 1.2em 0.2em 1.2em;
    @media (max-width: 1050px) {
      font-size: 0.9rem;
    }
    @media (max-width: 650px) {
      font-size: 0.5em;
    }
    .credit-card-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .credit-card-container {
        min-width: 40px;
        width: 55px;
        height: 24px;
        overflow: hidden;
        border-radius: 2px;
        background-color: #222222;
        border: 1px solid #222222;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;

        &.lightBg {
          background-color: #f5f5f5;
        }

        svg path {
          stroke: #fff;
        }

        @media (max-width: 1050px) {
          width: 40px;
        }
      }

      .credit-card-hidden-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1050px) {
          width: 40px;
        }
        @media (max-width: 650px) {
          width: 25px;
        }
      }

      .request-button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #00c2ff;
        font-size: 1em;
        font-weight: 600;
        cursor: pointer;
        @media (max-width: 1270px) {
          font-size: 0.7em;
        }
        @media (max-width: 650px) {
          font-size: 0.5em;
        }
      }
    }
  }
}

.credi-card-numbers {
  display: flex;

  .credit-card-hidden-numbers,
  .credit-card-container {
    margin-right: 0.4rem;
  }
}
