@media (max-height: 600px) {
  html .logo {
    position: unset;
  }

  html .imgContainer, html .pageContent {
    width: 100%;
  }

  html .imgContainer img {
    width: 100%;
    max-height: 35rem;
  }
}

@media (max-width: 990px) {
  html .logo {
    position: unset;
  }

  html .container {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 3rem;

    .imgContainer, .pageContent {
      width: 100%;
      margin: 0.5rem 0.5rem;
      align-self: center;
    }

    .imgContainer img {
      width: 100%;
      max-height: 20rem;
    }

    .pageContent {
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1.1rem;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;

  padding: 0 4rem;

  .imgContainer {
    align-self: flex-start;
  
    height: 100%;
    width: 50%;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageContent {
    height: calc(100% - 2rem);
    width: 50%;
  
    margin-top: 2rem;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    text-align: center;
  
    position: relative;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      color: #00c2ff;
    }

    p {
      font-size: 1.3rem;
      font-weight: 500;
      color: #c3c3c3;
    }
  }

  .logo {
    position: absolute;
    top: 0;
    margin-top: 2rem;
  
    height: 10rem;
  }

  .btn {
    background-color: #00c2ff;
    color: #fff;
    border: none;
    padding: 0.3rem 2rem;
    border-radius: 25px;
    font-size: 1.1rem;
    font-weight: 900;
    cursor: pointer;
    margin-top: 1rem;
    transition: filter 0.2s ease-in-out;

    &:hover {
      background-color: #00c2ff;
      color: #fff;
      filter: brightness(0.95);
    }
  }
}
