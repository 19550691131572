@media (max-width: 930px) {
  html .registerForm {
    h1 {
      font-size: 22px;
    }
  }
}

.signInForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-family: "Catamaran";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
  }

  p.instruction,
  a {
    text-align: center;
    font-family: "Catamaran";
    color: #6a6a6a;
    text-decoration: none;
  }

  .input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .registerBtn {
    width: 267px;
    height: 39px;
    background: #00c2ff;
    border-radius: 19.5px;
    color: #fff;

    &:hover {
      background: #00c2ff;
    }
  }
}
