@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  border: 2px solid #f3f3f3;
  border-top: 2x solid #3498db;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}
