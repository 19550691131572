.privacy-policy-container {
  height: 100vh;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 28px;
  }

  .logo {
    max-height: 200px;
    cursor: pointer;
  }
}

.terms-container {
  padding: 2em 2em 0 2em;
  overflow: scroll;

  color: #000000;
  font-size: 18px;

  &::-webkit-scrollbar {
    height: unset;
  }

  p {
    text-align: justify;
  }
}

.action-container {
  min-height: 8.5em;
  width: 100%;
  box-shadow: 0px 19px 144px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .refuse-button {
    color: #d4d4d4;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
}
