@media (max-width: 930px) {
  html main .container {
    width: 100%;
  }
}

.container {
  min-width: 22rem;
  max-width: 22rem;

  padding: 0 0 0 4em;
  position: relative;
  @media (max-width: 1270px) {
    max-width: 15rem;
    min-width: 15rem;
  }
}

.withRightBorder {
  height: 100%;
  width: 100%;
  border-right: 1px solid #dedede;
  padding-top: 4em;

  // @media (max-width: 1270px) {
  //   width: 60%;
  // }
}

.navBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 80%;
  margin: 0 auto;
  background-color: transparent;
  border: none;

  margin-top: 1.8rem;

  position: relative;

  color: #b8b8b8;
}

.navBtn.selected {
  color: #00b2ff;
}

.navIcon {
  position: absolute;
  left: 0;
}

.navBtn span {
  font-size: 1.3rem;
  font-weight: 500;
  @media (max-width: 1270px) {
    font-size: 0.9rem;
  }
}

.lessSpaceInLeft {
  margin-left: -2em;
}

.logoutBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 80%;
  background-color: transparent;
  border: none;

  position: absolute;

  bottom: 20px;
  left: 0;

  color: #fd4f29;
}

.logoutBtn svg {
  margin-right: 1.2em;
  margin-top: -0.3em;
}

.logoutBtn span {
  font-size: 1.6em;
  font-weight: 500;
}
