.userExperienceCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.8em;

  .quotesIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 15%;
    border-radius: 100%;
    background: linear-gradient(180deg, #63daff 0%, #00c2ff 100%);
    color: #ffffff;
    width: 46px;
    height: 46px;
  }

  .cardImg {
    position: absolute;
    left: 0;
    top: 15%;
    border-radius: 100%;
    width: 69px;
    height: 69px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c2ff;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 2.3em;
    padding: 2.5em 3rem;
    min-width: 18em;
    background-color: #ffffff;
    box-shadow: 0px 4px 14px 4px #00000014;
    border-radius: 10px;

    :nth-child(1) {
      color: #606060;
      font-size: 20px;
    }

    :nth-child(2) {
      margin-top: -4px;
      color: #808080;
      font-size: 15px;
    }

    .message {
      margin-top: 8px;
      color: #c0c0c0;
      font-size: 14px;
      text-align: justify;
    }
  }
}
