.edit-profile-content {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;

  .container {
    width: 100%;
    height: 100%;
    padding: 4em 4em 4em 4em;

    display: flex;
    flex-direction: column;
  }

  .edit-profile-picture {
    width: 4.5em;
    height: 4.5em;
    border-radius: 100%;
    margin-right: 20px;

    background: #c4c4c4;
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .edit-image {
    position: absolute;
    bottom: 0.2em;
    right: 0.2em;
    border: none;
    background-color: #00c2ff;
    color: #ffffff;
    width: 1.2em;
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .edit-image label {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  .user-container {
    display: flex;
    align-items: center;
    color: #00c2ff;

    width: 100%;
    font-size: 1.8em;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 2rem;
  }

  .input-label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000000;

    margin-bottom: 0.5rem;
  }

  .input,
  .textarea {
    width: 100%;
    max-width: unset;

    padding: 0.5rem;
    border-radius: 4px;
    border: 0;
    outline: 1px solid #b8b8b8;

    font-size: 1.1rem;
    color: #000000;
    background-color: #ffffff;

    &:disabled {
      background-color: transparent;
      color: #b8b8b8;
      cursor: not-allowed;
    }
  }

  select.input {
    border-right: 12px solid transparent;
  }

  .input::placeholder,
  .textarea::placeholder {
    color: #b8b8b8;
  }

  .input + .input {
    margin-top: 1rem;
  }

  .password-field {
    border-radius: 4px;
    border: 1px solid #b8b8b8;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .password-field.error {
    border-color: #f44c4c;
  }

  .new-pass-instruction {
    color: #b8b8b8;
    font-size: 0.95rem;
  }

  .password-field .input {
    outline: none;
    border: none;
    max-width: calc(100% - 2rem);

    color: #b8b8b8;
    letter-spacing: 0.2rem;
  }

  .password-field .active {
    color: #000000;
    letter-spacing: normal;
  }

  .password-field button {
    border: none;
    background-color: transparent;
    width: 2rem;
    height: 2rem;

    margin: -0.1rem 0.3rem 0 0.3rem;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b8b8b8;
  }

  .actions {
    margin-top: 5rem;
    align-self: flex-end;
  }
}
