.eventStatusBar {
  display: flex;
  flex-direction: row;
  margin: 0 4rem;

  .barOne,
  .barTwo,
  .barThree {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      color: #00c2ff;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 0.3rem;
      @media (max-width: 930px) {
        font-size: 0.9rem;
      }
      @media (max-width: 630px) {
        font-size: 0.6rem;
      }
    }

    .title.error {
      color: #fd4f29;
      @media (max-width: 930px) {
        font-size: 0.9rem;
      }
      @media (max-width: 630px) {
        font-size: 0.5rem;
      }
    }

    .bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 12px;
      padding: 0;
      margin: 0;
      background-color: #e8e8e8;
      @media (max-width: 930px) {
        height: 8px;
      }

      &.full {
        background-color: #00c2ff;
      }

      &.full.error {
        background-color: #fd4f29;
      }

      &.half {
        background: linear-gradient(90deg, #00c2ff 50%, #e8e8e8 50%);
      }
    }

    .updatedStatus {
      color: #e8e8e8;
      font-size: 20px;
      margin-top: 0.5rem;

      @media (max-width: 930px) {
        font-size: 16px;
      }
      @media (max-width: 630px) {
        font-size: 14px;
      }
    }
  }

  .barOne {
    .bar::before {
      content: "";
      height: 32px;
      width: 32px;
      background-color: #00c2ff;
      position: absolute;
      left: -5px;
      border-radius: 100%;
      z-index: 1;
      @media (max-width: 930px) {
        height: 20px;
        width: 20px;
      }
    }

    .bar.error::before {
      background-color: #fd4f29;
    }

    .bar::after {
      content: "";
      height: 32px;
      width: 32px;
      position: absolute;
      right: -5px;
      border-radius: 100%;
      background-color: #e8e8e8;
      z-index: 1;
      @media (max-width: 930px) {
        height: 20px;
        width: 20px;
      }
    }

    .bar.full::after {
      background-color: #00c2ff;
    }

    .bar.full.error::after {
      background-color: #fd4f29;
    }

    .bar.half::after {
      background-color: #e8e8e8;
    }
  }

  .barTwo,
  .barThree {
    .bar::after {
      content: "";
      height: 32px;
      width: 32px;
      position: absolute;
      right: -5px;
      border-radius: 100%;
      background-color: #e8e8e8;
      z-index: 1;
      @media (max-width: 930px) {
        height: 20px;
        width: 20px;
      }
    }

    .bar.full::after {
      background-color: #00c2ff;
    }

    .bar.full.error::after {
      background-color: #fd4f29;
    }

    .bar.half::after {
      background-color: #e8e8e8;
    }
  }
}
