@import "./utils/scss/fonts.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Catamaran" !important;
}

html,
body {
  height: 100%;
  // NOTE: This is not a good practice, but it's a quick fix for the home page gigantic scroll bar
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 200px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00c2ff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0291bd;
}

html {
  scroll-behavior: smooth;
}
textarea[name="contact"] {
  resize: none;
}

// cookies modal
.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 0.85rem;
    font-weight: 500;
  }

  button {
    font-size: 0.85rem;
    font-weight: 500;
    margin-top: 0.8rem;
    width: 12rem;
    padding: 0.2rem 0;
    border-radius: 12px;
    border: 0;
    background-color: #ffffff;
    color: #00c2ff;
    cursor: pointer;
  }
}
