@media (max-width: 1300px) {
  html .choice-question {
    font-size: 2rem;
  }
}

@media (max-width: 930px) {
  html .cake-image {
    display: none;
  }
}

@media (max-width: 650px) {
  html .choice-question {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  html .display-options-container {
    flex-direction: column;
    align-items: center;
  }
}

.register-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.cake-image {
  width: 35%;
  min-height: 100vh;
  background-image: url("../../assets/images/cake.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.register-form-container {
  width: 65%;
  display: flex;
  flex-direction: column;

  margin-bottom: 4rem;

  .register-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.choice-question {
  font-size: 3rem;
  font-family: 'Catamaran';
}

.display-options-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 150px;
}
