.mui-select-container *:focus {
  background-color: transparent !important;
}

.remove__mui__style {
  &.Mui-expanded {
    margin: 0 0 !important;
  }

  .Mui-expanded {
    margin: 0 0 !important;
    min-height: unset;
  }

  .MuiInput-input {
    display: flex;
  }
}
