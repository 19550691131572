.slick-slider {
  height: 460px;
}
.slick-next:before,
[dir="rtl"] {
  margin-left: -70px;
}
.slick-prev {
  margin-right: -200px;
  left: 0;
  color: #fff;
  z-index: 4;
  margin-left: 8px;
}
.slick-prev:before,
.slick-next:before {
  color: #00c2ff;
}
