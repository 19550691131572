@media (max-width: 930px) {
  html .banner h1 {
    font-size: 30px;
    width: unset;
    text-align: end;
  }

  html .banner button {
    width: 200px;
  }
}

.banner {
  width: 100%;
  height: 500px;
  background-image: url(../../assets/images/pool.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 60px;

  h1 {
    text-align: right;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-size: 58px;
    width: 70%;
  }
  button {
    border-style: none;
    background-color: #00c2ff;
    width: 20%;
    height: 50px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: bold;
    font-size: 20px;
    border-radius: 25px;
    margin-top: 30px;
    cursor: pointer;
    box-shadow: 0px 4px 14px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #00abdf;
    }
  }
}
