.autocomplete {
  input {
    width: 100%;
    border: none;

    box-sizing: border-box;
    font-size: 1.1rem;
    outline: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .autocomplete__results {
    list-style: none;

    li {
      padding: 0.5rem;
      cursor: pointer;
      font-size: 1rem;
      color: #000;

      &:hover {
        color: #626262;
      }
    }

    .activeSuggestion {
      color: #626262;
    }
  }
}
